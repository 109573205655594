<template>
  <div class='order-items'>
    <b-row cols="2" class="mb-3">
      <b-col>
        <b-form-group
          label='Vendor'
          :label-for='`vendor`'
          >
            <InventoryRecordVendor/>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label='Date'
          :label-for='`record-date`'
          >
            <b-form-datepicker id="record-date" :value="postDate" @input="onDateInput" class="mb-2"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row cols="2" class="mb-3">
      <b-col>
        <b-form-group
          label='Invoice Date'
          :label-for='`record-invoice-date`'
          >
            <b-form-datepicker id="record-invoice-date" :value="postInvoiceDate" @input="onInvoiceDateInput" class="mb-2"></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label='Invoice Number'
          :label-for='`record-invoice-number`'
          >
            <b-form-input id="record-invoice-number" :value="postInvoiceNumber" @input="onInvoiceNumberInput" class="mb-2"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row cols="1" class="mb-3">
      <b-col>
        <b-form-group
          label='Notes'
          :label-for='`record-notes`'
          >
            <b-form-textarea id="record-notes" :value="postNote" @input="onNoteInput" class="mb-2"></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row cols="1">
      <b-col>
        <b-table
          :fields='fields'
          :items='items'
          per-page=0
          head-variant=dark
          :tbody-tr-class='backorder_class'
          >

          <template #cell(product_name)="data">
            <InventoryRecordItemName :item='data.item' @item-updated='args => item_updated( data.item.id, args )' />
          </template>

          <template #cell(quantity)="data">
            <InventoryRecordItemQuantity :qty='parseInt( data.item.quantity )' @input='value => item_updated( data.item.id, { quantity: parseInt( value ) } )'/>
          </template>

          <template #cell(row-actions)="data">
            <b-row class='row-actions'>
              <b-col cols='auto'>
                <b-button class='duplicate-btn' variant='link' @click.prevent='duplicate_item( data.item.id )'>
                  <span class='sr-only'>Duplicate</span>
                  <font-awesome-icon icon="fa-regular fa-clone" />
                </b-button>
              </b-col>
              <b-col cols='auto'>
                <b-button class='delete-btn' variant='link' @click.prevent='delete_item( data.item.id )'>
                  <span class='sr-only'>Delete</span>
                  <font-awesome-icon icon="fa-regular fa-xmark" />
                </b-button>
              </b-col>
            </b-row>
          </template>

        </b-table>

        <div class='actions'>
          <b-row>
            <b-col cols='auto'>
              <SaveInventoryRecordButton />
            </b-col>
            <b-col cols='auto'>
              <AddInventoryRecordItems @added='id => item_updated( id, { quantity: 1 } )' />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from 'lodash';
import Big from 'big.js';
import { money_format } from '@/functions/formatting';
import InventoryRecordItemName from './table-columns/item-name';
import InventoryRecordItemQuantity from './table-columns/quantity';
import FixedDecimalNumberInput from '@/components/fixed-decimal-number-input';
import AddInventoryRecordItems from './add-items';
import InventoryRecordVendor from './vendor';
import SaveInventoryRecordButton from './save-items-btn';

import { mapState, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'InventoryRecordItems',
  components: {
    InventoryRecordItemName,
    InventoryRecordItemQuantity,
    FixedDecimalNumberInput,
    AddInventoryRecordItems,
    InventoryRecordVendor,
    SaveInventoryRecordButton,
  },
  computed: {

    ...mapState( 'inventory_details', [
      'record'
    ]),

    items () {
      return Object.values( this.record.line_items );
    },

    postDate () {
      return moment( this.record.date ).format('YYYY-MM-DD');
    },

    postInvoiceDate () {
      return moment( this.record.invoice_date ).format('YYYY-MM-DD');
    },

    postInvoiceNumber () {
      return this.record.invoice_number;
    },
    
    postNote () {
      return this.record.note;
    }

  },
  data () {
    return {
      fields: [
        {
          key: 'product_name',
          label: 'Product',
        },
        // {
        //   key: 'sku',
        //   label: 'SKU Number',
        //   formatter: 'get_sku',
        // },
        {
          key: 'quantity',
          label: 'Quantity',
        },
        {
          key: 'row-actions',
          label: '',
        }
      ],
    }
  },
  methods: {

    ...mapMutations( 'inventory_details', [
      'set_record_prop',
      'unset_record_prop',
      'set_item_changes_pending'
    ]),


    item_subtotal ( value, key, item ) {
      return money_format( Big( item.per_unit ).times( item.quantity ) );
    },

    onDateInput ( value ) {
      this.set_record_prop({
        prop: 'date',
        value: moment( value ).format('MMM D, YYYY')
      });

      this.set_item_changes_pending( true );
    },
    
    onInvoiceDateInput ( value ) {
      this.set_record_prop({
        prop: 'invoice_date',
        value: moment( value ).format('MMM D, YYYY')
      });

      this.set_item_changes_pending( true );
    },
    
    onInvoiceNumberInput ( value ) {
      this.set_record_prop({
        prop: 'invoice_number',
        value: value
      });

      this.set_item_changes_pending( true );
    },
    
    onNoteInput ( value ) {
      this.set_record_prop({
        prop: 'note',
        value: value
      });

      this.set_item_changes_pending( true );
    },


    // get_sku ( value, key, item ) {
    //   if ( item.variations ?? null ) {
    //     const variation = item.variations.find( x => x.id == item.variation_id );
    //     return ( variation && ( variation.sku ?? null ) ) ? variation.sku : '--';
    //   }
    //   return value;
    // },


    delete_item ( id ) {
      this.unset_record_prop( `line_items.${ id }` );
      this.set_item_changes_pending( true );
    },


    duplicate_item ( id ) {
      let item_clone = _.cloneDeep( this.record.line_items[ id ] );
      item_clone.id = _.uniqueId( 'new-item' );
      this.set_record_prop({
        prop: `line_items.${ item_clone.id }`,
        value: item_clone,
      });
      this.set_item_changes_pending( true );
    },


    item_updated ( item_id, args ) {
      let item_clone = _.cloneDeep( this.record.line_items[ item_id ] );
      Object.entries( args ).forEach( entry => {
        const [ key, value ] = entry;
        item_clone[ key ] = value;
      });

      // console.log(item_clone);

      // item_clone.per_unit = Big( item_clone.per_unit ).toNumber();
      // item_clone.subtotal = Big( item_clone.per_unit ).times( item_clone.quantity ).toNumber();
      // item_clone.subtotal_tax = Big( item_clone.subtotal ).times( item_clone.tax_rate ).div( 100 ).toNumber();
      // item_clone.total = item_clone.subtotal;
      // item_clone.tax = item_clone.subtotal_tax;

      this.set_record_prop({
        prop: `line_items.${ item_id }`,
        value: item_clone,
      });
      
      this.set_item_changes_pending( true );
    },


    backorder_class ( item, type ) {
      if ( item && type === 'row' && item.is_backordered ) {
        return 'backordered';
      }
      return null;
    },


  },
}
</script>


<style lang='scss' scoped>
.table {
  margin: 0;
  table-layout: fixed;
  & /deep/ th,
  & /deep/ td {
    vertical-align: middle;
    // int inputs
    &:nth-child( 2 ),
    &:nth-child( 3 ) {
      width: 18%;
    }
    // subtotal -- large enough for $xxxxx.xx
    &:nth-child( 4 ) {
      width: 110px;
    }
    // delete column
    &:last-child {
      width: 115px;
      & > .row {
        flex-wrap: nowrap;
      }
    }
  }
  & /deep/ tbody{
    border-bottom: 1px solid var( --bs-border-color );
  }
}
.actions {
  margin-top: 1rem;
  .row {
    justify-content: flex-end;
    --gutter-x: 0.5rem;
    --gutter-y: 0.5rem;
  }
  & /deep/ button {
    font-weight: bold;
  }
}
.row-actions {
  button {
    line-height: 1;
    border-radius: 50%;
    display: block;
    padding: 0;
    font-size: 1.25em;
    width: 1.75em;
    height: 1.75em;
    color: var( --icon-color );
    background-color: var( --white );
    transition: .3s ease;
    &:hover,
    &:focus {
      color: var( --white );
      background-color: var( --icon-color );
    }
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate( -50%, -50% );
    }
  }
}
.delete-btn {
  --icon-color: var( --danger );
}
.duplicate-btn {
  --icon-color: var( --blue );
}
</style>