<template>
  <b-input
    :id='id'
    class='fixed-decimal-number-input'
    type='number'
    number
    min=0
    :step='step'
    v-model='current_value'
    @input='handle_input'
    />
</template>


<script>
import Big from 'big.js';

export default {
  name: 'FixedDecimalNumberUnit',
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: [ Number, String ],
      required: true,
    },
    decimals: {
      type: [ Number, String ],
      default: 2,
    }
  },
  data () {
    return {
      current_value: null
    }
  },
  computed: {
    num_decimals () {
      return parseInt( this.decimals );
    },
    order_of_magnitude () {
      return Math.pow( 10, this.num_decimals );
    },
    step () {
      return ( 1 / this.order_of_magnitude ).toString();
    }
  },
  methods: {

    handle_input ( value ) {
      value = parseFloat( value );
      value = Big( isNaN( value ) ? 0 : value ).round( 2, Big.roundDown ).toNumber();
      this.$emit( 'input', value );
      this.$nextTick( () => {
        this.current_value = value;
      });
    }

  },
  watch:{
    value: {
      immediate: true,
      handler ( to ) {
        this.current_value = this.value;
      }
    }
  }
}
</script>