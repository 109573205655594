<template>
  <b-button variant=link @click='create_new' :disabled='busy'>
    <span v-if="icon != 'false'">
      <font-awesome-icon v-if='busy' icon='fa-solid fa-spinner' class='fa-spin' />
      <font-awesome-icon v-else icon='fa-light fa-receipt'/>
    </span>
    <span>{{ text ? text : 'New Record' }}</span>
  </b-button>
</template>


<script>
import axios from '@/axios';
import EventBus from '@/event-bus';

export default {
  name: 'CreateNewInventoryRecordLink',
  props: {
    action_block: {
      required: false,
      type: Function
    },
    text: {
      required: false
    },
    icon: {
      required: false
    }
  },
  data () {
    return{
      busy: false,
    }
  },
  methods: {
    create_new () {
      if ( !this.busy ) {
        if ( ( this.action_block ?? null ) && this.action_block() ) {
          return;
        }
        this.busy = true;
        const busy_id = this.$jjToast({
          type: 'busy',
          title: 'Loading',
          content: 'Creating new record',
        });
        new Promise( ( resolve, reject ) => {
            axios.post( `${ process.env.VUE_APP_BASE_SITE_URL }/wp-json/wp/v2/inventory_records`, {
              title: new Date().getTime(),
              status: 'publish'
            } )
              .then( response => {
                this.$router.push({
                  name: 'inventory/record-details',
                  params: {
                    id: response.data.id,
                  },
                });
                EventBus.$emit( 'inventory-report-created', response.data.id );
                resolve();
              })
              .catch( error => {
                reject( error );
              })
          })
          .catch( () => {
            this.$jjToast({
              type: 'error',
              title: 'Error',
              content: `Failed to create new record`,
            });
          })
          .finally( () => {
            this.busy = false;
            this.$bvToast.hide( busy_id );
          });
      }
    }
  }
}
</script>

<style lang='scss' scoped>
    button.btn.btn-link {
      padding: 0;
      color: var( --secondary ) !important;
      &:hover,
      &:focus {
        color: var( --primary ) !important;
      }
      .svg-inline--fa,
      .spinner-border {
        margin-right: 0.5em;
      }
    }
</style>