<template>
  <div class='order-item-qty'>
    <b-input
      type='number'
      min=0
      step=1
      :value='qty'
      @input='update_qty'
      />
  </div>
</template>

<script>
export default {
  name: 'InventoryRecordItemQuantity',
  props: {
    qty: {
      type: Number,
      required: true,
    }
  },
  methods: {
    update_qty ( value ) {
      this.$emit( `input`, value );
    }
  }
}
</script>