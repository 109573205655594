<template>
  <div class="vendor">
    <vue-multiselect
        placeholder='Select vendor'
        id='vendor_selector'
        :multiple='false'
        :allow-empty='false'
        :searchable='true'
        v-model='selected'
        :options='options'
        :optionHeight="200"
        :close-on-select='false'
        :clear-on-select='false'
        @select='on_select'
        :taggable="true"
        @tag="addTag"
        >
        <span slot='noResult'>No vendors found matching search.</span>
    </vue-multiselect>
  </div>
</template>

<script>
import axios from '@/axios';
import _ from 'lodash';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'InventoryRecordVendor',
  data() {
    return {
      abort_controller: null,
      search_busy: false,
      busy: false,
    }
  },
  computed: {
    ...mapState( 'inventory_details', [
      'record',
    ]),

    options () {
      return this.record.vendors;
    },

    selected () {
      return this.record.vendor;
    }
  },
  methods: {

    ...mapMutations( 'inventory_details', [
      'set_record_prop',
      'set_item_changes_pending'
    ]),

    on_select ( selectedOption ) {
        this.set_record_prop({
            prop: `vendor`,
            value: selectedOption
        });

        this.set_item_changes_pending( true );
    },

    addTag ( newTag ) {
      let vendors = _.cloneDeep( this.record.vendors );
      vendors.push( newTag );

      this.set_record_prop( {
        prop: `vendors`,
        value: vendors
      } );
      
      this.set_record_prop( {
        prop: `vendor`,
        value: newTag
      } );
    },

    reset () {
      this.selected = [];
    },

  },
}
</script>