<template>
  <div class="inventory-report-deleter">
    <b-button variant='link' @click='$refs.modal.show()'>Delete Record</b-button>
    <b-modal
      ref='modal'
      class='delete-inventory-report-modal'
      centered
      title='Delete Inventory Report'
      size=md
      ok-title='Delete'
      ok-variant='danger'
      cancel-variant='medium'
      @ok='delete_inventory_report'
      :busy='busy'
      >
      <p>Are you sure you want to delete this inventory report?</p>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import EventBus from '@/event-bus';
import axios from '@/axios';

export default {
  name: 'InventoryRecordDeleter',
  data () {
    return {
      busy: false,
    }
  },
  computed: {
    ...mapState( 'inventory_details', [
      'record'
    ]),
  },
  methods: {

    ...mapMutations( 'inventory_details', {
      set_record: 'record',
    }),

    delete_inventory_report ( e ) {
      e.preventDefault();
      this.busy = true;
      const busy_id = this.$jjToast({
        type: 'busy',
        title: 'Deleting',
        content: 'Deleting inventory record',
      });
      axios.delete( `${ process.env.VUE_APP_BASE_SITE_URL }/wp-json/wp/v2/inventory_records/${ this.record.id }` )
        .then( () => {
          this.$bvToast.hide( busy_id );
          this.$router.push({ name: 'inventory/records' });
          EventBus.$emit( 'inventory-report-deleted', this.record.id );
        })
        .catch( () => {
          this.busy = false;
          this.$bvToast.hide( busy_id );
          this.$jjToast({
            type: 'error',
            title: 'Error',
            content: 'Failed to delete inventory record',
          });
        });
    },

  }
}
</script>