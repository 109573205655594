<template>
  <div class='save-items-btn'>
    <b-button
      :variant='disabled ? `outline-medium` : `success`'
      :disabled='disabled'
      @click='save_items'
      >
      <font-awesome-icon v-if='busy' icon='fa-solid fa-spinner' class='fa-spin' />
      <font-awesome-icon v-else icon="fa-solid fa-floppy-disk" />
      Save Changes
    </b-button>

    <b-modal id="modalError" title="Product Misconfigured" ok-only>
    <p>
      Please select a valid variation for these product(s) to save:
      <ul v-for="e in errors" :key="e.id">
        <li>{{ e.product_name }}</li>
      </ul>
      
    </p>
  </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'SaveInventoryRecordButton',
  data () {
    return {
      busy: false,
      errors: false,
    }
  },
  computed: {

    ...mapState( 'inventory_details', [
      'record',
      'item_changes_pending',
    ]),

    disabled () {
      return this.busy || ! this.item_changes_pending;
    },

  },
  methods: {

    ...mapMutations( 'inventory_details', [
      'set_item_changes_pending',
      'set_record_prop',
    ]),

    save_items () {
      this.errors = [];

      for ( var i in this.record.line_items ) {
        if ( typeof this.record.line_items[i].variations !== 'undefined' && this.record.line_items[i].variations.length > 0 ) {
          if ( this.record.line_items[i].variation_id == 0 ) {
            this.errors.push( this.record.line_items[i] );
          }
        }
      }

      if ( this.errors.length ) {
        this.$bvModal.show('modalError');
        return;
      }

      this.busy = true;
      
      const busy_id = this.$jjToast({
        type: 'busy',
        title: 'Saving',
        content: 'Saving order items',
      });
      
      axios.post( `${ process.env.VUE_APP_BASE_SITE_URL }/wp-json/wp/v2/inventory_records/${ this.record.id }`,
        {
          date: moment( this.record.date ).format('YYYY-MM-DDTHH:MM:SS'),
          vendor: this.record.vendor,
          _inventory: this.record.line_items,
          meta: {
            "invoice_date": this.record.invoice_date,
            "invoice_number": this.record.invoice_number,
            "note": this.record.note,
          },
        
        } )
        .then( (response) => {
          this.set_item_changes_pending( false );

          this.$jjToast({
            type: 'success',
            title: 'Updated',
            content: 'Saved inventory record',
          });
        })
        .catch( () => {
          this.$jjToast({
            type: 'error',
            title: 'Error',
            content: 'Failed to save inventory record',
          });
        })
        .finally( () => {
          this.busy = false;
          this.$bvToast.hide( busy_id );
        });
    },

  },
}
</script>