<template>
  <div class='add-order-item'>
    <b-button variant='primary' @click='$refs[`modal`].show()'>
      <font-awesome-icon icon='fa-solid fa-plus' />
      Add Product
    </b-button>

    <b-modal
      ref='modal'
      class='add-order-item-modal'
      centered
      title='Add Product to Order'
      size=md
      ok-title='Add'
      ok-variant='primary'
      cancel-variant='medium'
      @ok='add_items'
      @show='reset'
      :busy='busy'
      >
      <vue-multiselect
        placeholder='Select products'
        id='product_selector'
        :multiple='true'
        :allow-empty='true'
        :searchable='true'
        trackBy='index'
        label='name'
        v-model='selected'
        :options='options'
        :loading='search_busy'
        @search-change='search_products'
        :internal-search='false'
        :optionHeight="200"
        :showLabels='false'
        :close-on-select='false'
        :clear-on-select='false'
        @select='on_select'
        @input='input'
        >
        <span slot='noResult'>No products found matching search.</span>
      </vue-multiselect>
    </b-modal>
  </div>
</template>

<script>
import axios from '@/axios';
import _ from 'lodash';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'AddInventoryRecordItems',
  data() {
    return {
      abort_controller: null,
      search_busy: false,
      busy: false,
      selected: [],
      options: [],
    }
  },
  computed: {
    ...mapState( 'inventory_details', [
      'record'
    ]),
  },
  methods: {

    ...mapMutations( 'inventory_details', [
      'set_record_prop',
    ]),


    reset () {
      this.selected = [];
    },


    input () {
      // console.log('this.selected', this.selected );
      this.selected[ this.selected.length - 1 ].index = Date.now();
    },
    
    on_select () {
      this.options = _.cloneDeep( this.options );
    },


    add_items () {
      this.selected.forEach( product => {
        const uniqid = _.uniqueId( 'new-item' );
        this.set_record_prop({
          prop: `line_items.${ uniqid }`,
          value: {
            id: uniqid,
            product_id: product.id,
            variation_id: 0,
            sku: product.sku,
            name: product.name,
            product_name: product.name,
            attributes: product.attributes ?? [],
            meta_data: product.attributes.map( x => {
              return {
                key: x.key,
                value: ''
              };
            }),
            variations: product.variations ?? null,
            per_unit: product.price ? product.price : 0,
            quantity: 0,
            subtotal: 0,
            subtotal_tax: 0,
            total: 0,
            total_tax: 0,
            tax_rate: product.tax_rate,
          },
        });
        this.$emit( 'added', uniqid );
      });
    },


    search_products: _.debounce( function ( search ) {
      if ( this.abort_controller ) {
        this.abort_controller.abort();
      }
      this.abort_controller = new AbortController();
      this.search_busy = true;
      axios
        .get( `${ process.env.VUE_APP_BASE_SITE_URL }/wp-json/wc/v3/products`, {
          signal: this.abort_controller.signal,
          params: {
            jjprodsearch: true,
            search,
            per_page: 50,
            status: 'publish',
            // exclude: this.selected.map( x => x.id ),
          }
        })
        .then( response => {
          this.options = response.data;
          this.search_busy = false;
        })
        .catch( e => {
          if ( _.get( e, 'constructor.name' ) != 'Cancel' ) {
            this.search_busy = false;
          }
        })
    }, 100 ),

  },
}
</script>