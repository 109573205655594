<template>
  <div class='order-item-name'>
    <div class='name'>
      <span>{{ product_name }}</span>
    </div>
    <div v-if='attributes.length' class='attributes pr-lg-3'>
      <b-row>
        <b-col v-for='attribute in attributes' :key='attribute.key' cols=12 xl=6>
          <vue-multiselect
            :placeholder='attribute.name'
            :options='attribute.options'
            label='label'
            :multiple='false'
            :searchable='false'
            :allow-empty='false'
            :value='get_selected_attribute( attribute.key )'
            @input='value => update_selected_attr( attribute.key, value )'
            :showLabels='false'
            />
        </b-col>
      </b-row>
      <b-row v-if='badges'>
        <b-col cols=12 xl=6>
          <div class='badges'>
            <div v-for='badge, i in badges' :key='`badge-${i}`'>
              <b-badge :variant='badge.variant'>{{ badge.text }}</b-badge>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'InventoryRecordItemName',
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      local_item: null,
      item_invalid: false,
      item_disabled: false,
    }
  },
  computed: {
    product_name () {
      return this.local_item.product_name ?? ( this.local_item.name ?? '' );
    },
    attributes () {
      return ( this.item.attributes ?? [] ).sort( ( a, b ) => a.position > b.position ? 1 : ( a.position < b.position ? -1 : 0 ) );
    },
    badges () {
      let badges = [];
      if ( this.local_item.is_backordered ) {
        badges.push({
          variant: 'info',
          text: 'Backordered',
        });
      }
      if ( this.item.variations ?? null ) {
        let selected_variation = this.item.variations.find( variation => {
          return variation.id == this.local_item.variation_id;
        });
        if ( !selected_variation ) {
          badges.push({
            variant: 'danger',
            text: 'Invalid Variation',
          });
        } else if ( selected_variation.status != 'publish' ) {
          badges.push({
            variant: 'warning',
            text: 'Disabled Variation',
          });
        }
      }
      return badges;
    }
  },
  watch: {
    item: {
      immediate: true,
      handler ( new_val ) {
        this.local_item = _.cloneDeep( new_val );
      }
    },
  },
  methods: {

    get_selected_attribute ( key ) {
      const selected_value = this.get_selected_attribute_value( key );
      const attr = selected_value ? _.get( this.local_item, 'attributes', [] ).find( x => x.key == key ) : null;
      return attr ? _.get( attr, 'options', [] ).find( x => x.id == selected_value ) : null;
    },

    get_selected_attribute_value ( key ) {
      const attr = this.local_item ? this.local_item.meta_data.find( x => x.key == key ) : null;
      return attr ? attr.value : null;
    },

    update_selected_attr ( attr, value ) {
      value = _.get( value, 'id', null );
      let meta_obj = this.local_item.meta_data.find( x => x.key == attr );
      if ( !meta_obj ) {
        meta_obj = { key: attr };
        this.local_item.meta_data.push( meta_obj )
      }
      meta_obj.value = value;
      const selected_variation = this.item.variations.find( variation => {
        return !Object.entries( variation.attributes ).some( entry => {
          const [ key, value ] = entry;
          return ! [ this.get_selected_attribute_value( key ), '' ].includes( value );
        });
      });
      
      this.$emit( 'item-updated', {
        meta_data: this.local_item.meta_data,
        variation_id: selected_variation ? selected_variation.id ?? 0 : 0,
      });
    }

  }
}
</script>


<style lang='scss' scoped>
.attributes {
  > .row {
    --gutter-x: 0.5rem;
    --gutter-y: 0.5rem;
  }
}
.multiselect {
  font-size: 12px !important;
}
.badges {
  --gutter-x: .25em;
  --gutter-y: var( --gutter-x );
  display: flex;
  flex-flow: row wrap;
  margin: calc( var( --gutter-y ) * -1 ) calc( var( --gutter-x ) / -2 ) 0;
  > div {
    margin-top: var( --gutter-y );
    padding: 0 calc( var( --gutter-x ) / 2 );
    width: auto;
    flex: 0 0 auto;
  }
}
</style>