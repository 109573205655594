<template>
  <div class='order-details'>
    <jj-loading v-if='loading' centered />
    <template v-else>

      <b-row class='details-top-row'>
        <b-col cols=12 lg=auto class='order-info'>
          <h2>Inventory Record</h2>
        </b-col>

        <b-col cols=auto>
          <b-row class='order-actions'>
            <b-col cols=auto>
              <CreateNewInventoryRecordLink :action_block='maybe_block_action'/>
            </b-col>
            <!-- <b-col cols=auto>
              <CloneOrderLink :action_block='maybe_block_action'/>
            </b-col> -->
            <!-- <b-col cols=auto>
              <OrderPdfExporter type='picking' :action_block='maybe_block_action'/>
            </b-col> -->
            <b-col cols=auto>
              <InventoryRecordPdfExporter :action_block='maybe_block_action'/>
            </b-col>
            <b-col cols=auto>
              <InventoryRecordDeleter />
            </b-col>
          </b-row>
        </b-col>

      </b-row>


      <div class="py-4">
        <!-- <OrderStatusUpdater :action_block='maybe_block_action'/> -->
      </div>

      <InventoryRecordItems />

      <b-modal
        ref='action_blocked_modal'
        class='action-blocked-modal'
        centered
        title='Pending Changes'
        size=md
        ok-only
        >
        <p>You must save pending changes in order to proceed.</p>
      </b-modal>

    </template>
  </div>
</template>

<script>
import CreateNewInventoryRecordLink from './items/new';
// import OrderPdfExporter from './pdf-exporter';
import InventoryRecordDeleter from './items/delete';
import InventoryRecordItems from './items/index';
import InventoryRecordPdfExporter from './items/pdf-exporter';

import { mapState, mapMutations, mapActions } from 'vuex';

import EventBus from '@/event-bus';

export default {
  name: 'InventoryRecordDetails',
  components: {
    InventoryRecordItems,
    InventoryRecordDeleter,
    CreateNewInventoryRecordLink,
    InventoryRecordPdfExporter
  },
  computed: {

    ...mapState( 'inventory_details', [
      'loading',
      'record',
      'item_changes_pending',
    ]),

  },
  methods: {

    ...mapActions( 'inventory_details', [
      'load_record',
    ]),

    before_leave_guard ( next ) {
      if ( this.item_changes_pending && !window.confirm( 'Do you really want to leave? you have unsaved changes!' ) ) {
        return false;
      }
      next();
    },


    maybe_block_action () {
      if ( this.item_changes_pending ) {
        this.$refs.action_blocked_modal.show();
        return true;
      }
      return false;
    },


  },
  watch:{
    $route: {
      immediate: true,
      handler ( to ) {
        this.load_record( to.params.id );
      }
    }
  },
  created () {
    EventBus.$on( 'order-status-updated', this.receive_order_status_update );
  },
  beforeDestroy () {
    EventBus.$off( 'order-status-updated', this.receive_order_status_update );
  },
  beforeRouteUpdate ( to, from, next ) {
    this.before_leave_guard( next );
  },
  beforeRouteLeave ( to, from, next ) {
    this.before_leave_guard( next );
  }
}
</script>


<style lang='scss'>
.order-details {
  position: relative;
  padding: 1.5rem;
  flex: 1 1 0;
  overflow-y: auto;
  .h2 {
    margin: 0;
  }
  .details-top-row {
    --gutter-y: 1rem;
    justify-content: space-between;
  }
  .order-actions {
    // --gutter-x: 0.5rem;
    --gutter-y: 0.5rem;
    & .btn.btn-link {
      padding: 0;
      color: var( --secondary ) !important;
      &:hover,
      &:focus {
        color: var( --primary ) !important;
      }
      .svg-inline--fa,
      .spinner-border {
        margin-right: 0.5em;
      }
    }
  }
}
</style>