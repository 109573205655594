<template>
  <div id='no-record-selected'>
    <h2>No Record Selected</h2>
    <p>Select an record or <CreateNewInventoryRecordLink text="create a new record" icon="false"/>.</p>
  </div>
</template>


<script>
import { mapActions } from 'vuex';

import CreateNewInventoryRecordLink from './details/items/new';

export default {
  name: 'NoRecordSelected',
  components: {
    CreateNewInventoryRecordLink
  },
  data () {
    return {
      busy: false,
    }
  },
  methods: {

    ...mapActions( 'orders', {
      create_new_order: 'create_new'
    }),

    new_order () {
      if ( !this.busy ) {
        this.busy = true;
        const busy_id = this.$jjToast({
          type: 'busy',
          title: 'Loading',
          content: 'Creating new record',
        });
        this.create_new_order()
          .catch( () => {
            this.$jjToast({
              type: 'error',
              title: 'Error',
              content: 'Failed to create new record',
            });
          })
          .finally( () => {
            this.busy = false;
            this.$bvToast.hide( busy_id );
          });
      }
    }

  }
}
</script>


<style scoped lang='scss'>
#no-record-selected {
  padding: 1.5rem;
  text-align: center;
}
button {
  color: var( --secondary );
  display: inline;
  vertical-align: baseline;
  padding: 0;
  text-decoration: underline;
}
p {
  margin: 1rem 0 0;
}
</style>